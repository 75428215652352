import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import Cookies from "js-cookie";
import Konami from "react-konami-code";

import "assets/scss/material-kit-react.scss?v=1.10.0";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import ReactGA from "react-ga4";
import App from "app";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1292dd",
    },
  },
});
var hist = createBrowserHistory();
if (Cookies.get("rcl_statistics_consent") === true) {
  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}

var doABarrelRoll = function () {
  var a = "-webkit-",
    b = "transform:rotate(1turn);",
    c = "transition:4s;";
  document.head.innerHTML += "<style>body{" + a + b + a + c + b + c;
};

ReactDOM.render(
  <>
    <Konami action={doABarrelRoll} />
    <ThemeProvider theme={theme}>
      <Router history={hist}>
        <App />
      </Router>
    </ThemeProvider>
  </>,
  document.getElementById("root")
);

import React from "react";
import { useLocation } from "react-router-dom";
import { CookieBanner } from "@palmabit/react-cookie-law";
import ReactGA from "react-ga4";

import "assets/scss/material-kit-react.scss?v=1.10.0";

// pages for this product
//import Components from "views/Components/Components.js";
import ScrollToTop from "views/ScrollToTop";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Routes from "routes";

export default function App() {
  const location = useLocation();

  return (
    <>
      <ScrollToTop />
      <Header
        color={location.pathname === "/" ? "transparent" : "white"}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 150,
          color: "white",
        }}
      />
      <Routes />
      <Footer />
      <CookieBanner
        message="Wij gebruiken cookies om uw instellingen en voorkeuren te onthouden. U kunt deze cookies uitzetten via uw browser maar dit kan het functioneren van onze website negatief aantasten.
"
        acceptButtonText="Akkoord"
        policyLink="/legal"
        wholeDomain={true}
        necessaryOptionText="Noodzakelijk"
        statisticsOptionText="Statistieken"
        managePreferencesButtonText="Voorkeuren beheren"
        savePreferencesButtonText="Voorkeuren opslaan"
        preferencesOptionText="Sitevoorkeuren"
        showMarketingOption={false}
        onAcceptStatistics={() => {
          ReactGA.initialize(process.env.REACT_APP_GA_KEY);
          ReactGA.send("pageview");
        }}
        styles={{
          dialog: {
            position: "fixed",
            right: "1rem",
            zIndex: "100000",
            backgroundColor: "rgb(237, 239, 245)",
            padding: "10px",
            bottom: "1rem",
            maxWidth: "24rem",
          },
          button: {
            display: "inline-block",
            backgroundColor: "rgb(18, 146, 221)",
            padding: "3px",
            minWidth: "80px",
            color: "rgb(255, 255, 255)",
            textDecoration: "none",
            fontSize: "10pt",
            fontWeight: "400",
            marginRight: "5px",
            marginLeft: "5px",
            textAlign: "center",
            whiteSpace: "nowrap",
            cursor: "pointer",
            borderStyle: "none",
            "&:hover": {
              backgroundColor: "rgb(56, 184, 255)",
            },
          },
        }}
      />
    </>
  );
}

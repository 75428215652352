/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link to="/" color="transparent" className={classes.navLink}>
          Home
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/diensten" color="transparent" className={classes.navLink}>
          Diensten
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/over-ons" color="transparent" className={classes.navLink}>
          Over ons
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/team" color="transparent" className={classes.navLink}>
          Team
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/contact" color="#1292dd" className={classes.navLink}>
          Contact
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          to="/tipsandtricks"
          color="#1292dd"
          className={classes.navLinkWithBorder}
        >
          Tips &amp; Tricks
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/afspraken" className={classes.navLinkEmphasis}>
          <span>Maak een afspraak</span>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="telephone-tooltip"
          title="Contacteer ons telefonisch"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <span>
            <Button
              color="transparent"
              href="tel:+3214808383"
              title="Neem telefonisch contact op"
              className={classes.navLinkIcon}
            >
              <i className={classes.socialIcons + " fas fa-phone"} />
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          id="mail-tooltip"
          title="Contacteer ons via email"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <span>
            <Button
              color="transparent"
              href="mailto:info@integralebedrijfsbegeleiding.be"
              title="Neem contact op via email"
              className={classes.navLinkIcon}
            >
              <i className={classes.socialIcons + " fa fa-envelope"} />
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          id="facebook-tooltip"
          title="Volg ons op Facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <span>
            <Button
              color="transparent"
              href="https://www.facebook.com/IntegraleBedrijfsbegeleiding/"
              title="Volg ons op facebook"
              target="_blank"
              className={classes.navLinkIcon}
            >
              <i className={classes.socialIcons + " fab fa-facebook"} />
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          id="mail-tooltip"
          title="Volg ons op LinkedIn"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <span>
            <Button
              color="transparent"
              href="https://be.linkedin.com/company/boekhoudkantoor-integrale-bedrijfsbegeleiding"
              title="Volg ons op LinkedIn"
              target="_blank"
              className={classes.navLinkIcon}
            >
              <i className={classes.socialIcons + " fab fa-linkedin"} />
            </Button>
          </span>
        </Tooltip>
      </ListItem>
    </List>
  );
}
